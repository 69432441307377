import { render, staticRenderFns } from "./RegionsPage.vue?vue&type=template&id=5ad94e78&scoped=true&"
import script from "./RegionsPage.vue?vue&type=script&lang=js&"
export * from "./RegionsPage.vue?vue&type=script&lang=js&"
import style0 from "./RegionsPage.vue?vue&type=style&index=0&id=5ad94e78&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ad94e78",
  null
  
)

export default component.exports