<template>
  <div class="regions-page fill-height">
    <sidebar-section
      :select-elements="$options.sidebarSections"
      :title="$options.sidebarSectionsTitle"
    />
    <div class="regions-page__content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { REGIONS_PAGES, REGIONS_TYPE_NAMES, REGIONS_SIDEBAR_ELEMENTS } from '@/constants/regions';
import SidebarSection from '../components/common/SidebarSection.vue';



const INITIAL_REGIONS_PAGE = REGIONS_PAGES.COUNTRIES;

const SIDEBAR_SECTION_ELEMENTS = Object.values(REGIONS_SIDEBAR_ELEMENTS);
const SIDEBAR_SECTION_TITLE = 'Regions';

export default {
  name: 'RegionsPage',
  components: { SidebarSection },
  sidebarSections: SIDEBAR_SECTION_ELEMENTS,
  sidebarSectionsTitle: SIDEBAR_SECTION_TITLE,
  mounted() {
    const route = this.$route.matched.find(matchedRoute => {
      const { name } = matchedRoute;

      return REGIONS_TYPE_NAMES.includes(name);
    });

    if (route || this.$route.name === INITIAL_REGIONS_PAGE) {
      return;
    }

    this.$router.replace({ name: INITIAL_REGIONS_PAGE });
  }
};
</script>

<style lang="scss" scoped>
.regions-page {
  position: relative;

  &__content {
    height: 100%;
    padding-left: 240px;
  }
}
</style>
